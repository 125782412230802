:root {
  --header-background-color: #fff;
  --header-padding-top: 12px;
  --header-padding-bottom: 12px;
  --header-box-shadow: none;
  --header-border-bottom-color: #efefef;
  --header-border-bottom-style: solid;
  --header-border-bottom-width: 1px;

  /* Colors */
  --ql-color-accent1: #4e197c;
  --ql-color-accent1-lighten1: #734696;
  --ql-color-accent1-lighten2: #855ca3;
  --ql-color-accent1-lighten3: #9672b0;
  --ql-color-accent1-lighten4: #a889bd;
  --ql-color-accent1-lighten5: #b9a0ca;
  --ql-color-accent1-lighten6: #cbb7d7;
  --ql-color-accent1-lighten7: #dccee4;
  --ql-color-accent1-lighten8: #ede7f2;

  --ql-font-family-main: 'Open Sans', sans-serif;
  --ql-font-family-headings: 'Montserrat Alternates', sans-serif;

  /* Colors */

  /* accent */
  --color-accent: 79, 24, 125;
  --ql-color-accent1: rgb(var(--color-accent));
  --ql-color-accent1-t-lighten1: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten1)
  );
  --ql-color-accent1-t-lighten2: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten2)
  );
  --ql-color-accent1-t-lighten3: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten3)
  );
  --ql-color-accent1-t-lighten4: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten4)
  );
  --ql-color-accent1-t-lighten5: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten5)
  );
  --ql-color-accent1-t-lighten6: rgb(
    var(--color-accent),
    var(--ql-color-blending-lighten6)
  );

  --color-accent2: 104, 59, 143;
  --ql-color-accent2: rgb(var(--color-accent2));

  /* dark */
  --color-dark: 46, 32, 88;
  --ql-color-dark: rgba(var(--color-dark));
  --ql-color-dark-pure: rgb(51, 63, 63);
  --ql-color-dark-lighten1: rgba(var(--color-dark), 0.84);
  --ql-color-dark-lighten2: rgba(var(--color-dark), 0.64);
  --ql-color-dark-lighten3: rgba(var(--color-dark), 0.4);
  --ql-color-dark-lighten4: rgba(var(--color-dark), 0.24);
  --ql-color-dark-lighten5: rgba(var(--color-dark), 0.16);
  --ql-color-dark-lighten6: rgba(var(--color-dark), 0.08);

  /* grey */
  --color-grey: 240, 240, 240;
  --color-grey-9: 242, 242, 242;
  --ql-color-grey: rgb(var(--color-grey));
  --ql-color-grey-9: rgb(var(--color-grey-9));

  /* Neutral color */
  --color-white: 255, 255, 255;

  /* Typography */
  --ql-typography-text-color-primary-reverse: var(--ql-color-white);

  --ql-font-size-avatar: 150px;

  --ql-font-size-coins-small-mobile: 12px;
  /* Outline */
  --ql-outline-color-danger: var(--ql-color-danger-t-lighten4);

  /* Layout */
  --ql-general-layout-px-xxs: 16px; /* mobile (portrait): 0 - 576px */

  --ql-layout-background: var(--ql-color-dark-t-lighten6);

  /* Typography */
  --ql-typography-text-color-primary-reverse: var(--ql-color-white);

  /* Header */
  --ql-header-height: 64px;
  --ql-header-menu-bg: #e0e0e0;
  --ql-header-bg: var(--ql-color-accent1);
  --ql-header-text-color: var(--ql-color-white);
  --ql-header-avatar-bg: var(--ql-account-avatar-bg);
  --ql-header-avatar-text-color: var(--ql-color-white);
  /* Footer */
  --ql-footer-bg: #e0e0e0;
  --ql-footer-text-color: var(--ql-typography-text-color-primary);

  /* Menu */
  --ql-menu-horizontal-height: 46px;
  --ql-menu-bg: var(--ql-header-menu-bg);
  --ql-menu-text-color: var(--ql-menu-item-color);
  --ql-menu-text-color-active: var(--ql-menu-item-color-hover);

  /* Borders */

  /* Border radius */
  --ql-border-radius-small: 4px;
  --border-width-default: 1px;
  --border-style-default: solid;
  /* SPACINGS */
  --ql-spacing-xs: 4px;
  --ql-spacing-sm: 8px;
  --ql-spacing-md: 16px;
  --ql-spacing-lg: 32px;
  --ql-spacing-xl: 48px;
  --ql-spacing-xxl: 64px;

  /* Paddings */
  --ql-padding-xs: var(--ql-spacing-xs);
  --ql-padding-sm: var(--ql-spacing-sm);
  --ql-padding-md: var(--ql-spacing-md);

  /* Margins */
  --ql-margin-xs: var(--ql-spacing-xs);
  --ql-margin-sm: var(--ql-spacing-sm);
  --ql-margin-md: var(--ql-spacing-md);
  --ql-margin-xl: var(--ql-spacing-xl);

  /* Aside */
  --ql-aside-background: var(--ql-color-white);
  --ql-aside-padding: 24px;

  /* Button */
  --btn-border-color: var(--ql-color-dark-t-lighten4);
  --btn-border-radius-base: var(--ql-border-radius-small);
  --btn-default-color: var(--ql-typography-text-color-secondary);
  --btn-default-hover-bg: transparent;
  --btn-default-hover-color: var(--ql-color-accent1);

  --btn-text-hover-bg: var(--ql-color-dark-t-lighten5);
  --btn-text-hover-color: var(--ql-color-dark);

  --btn-circle-box-shadow: 0px 2px 5px 0px var(--ql-color-dark-pure);
  /* Dropdown */
  --dropdown-item-default-bg-hover: var(--ql-color-dark-t-lighten5);
  --dropdown-item-danger-bg-hover: var(--ql-color-danger-t-lighten5);

  /* Menu */
  --ql-menu-group-title-font-weight: var(--ql-font-weight-semibold);
  --ql-menu-group-title-color: var(--ql-color-dark-t-lighten2);
  --ql-menu-group-title-padding: 8px 24px;
  --ql-menu-item-icon-margin-right: var(--ql-margin-md);

  /* Account */
  --ql-account-avatar-box-shadow-hover: var(--ql-account-avatar-box-shadow);
  --ql-form-item-vertical-spacing-without-message: 24px;

  --ql-font-size-avatar-sm: 40px;
  --ql-font-size-avatar-md: 60px;
  --ql-font-size-avatar-lg: 80px;
  --ql-font-size-avatar-xl: 110px;

  --ql-account-title-color: var(--ql-color-white);

  /* Card */
  --ql-card-background: var(--ql-color-accent1-t-lighten6);
  --ql-card-border-default: 1px solid transparent;
  --ql-card-border-selected: 1px solid var(--ql-color-accent1-t-lighten4);

  /* Grid */
  --ql-grid-gutter: var(--ql-grid-gutter-sm);
  --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);

  /* Input */
  --input-bg: var(--ql-color-white);

  /* Modal */
  --modal-header-bg: var(--ql-color-dark);
  --modal-heading-color: var(--ql-color-white);
  --modal-border-radius: var(--ql-border-radius-small);

  /* Box-shadow */
  --box-shadow-default: 0px 0px 0px rgba(var(--color-dark), 0.02),
    0px 0px 36px rgba(var(--color-dark), 0.3),
    0px 27px 27px rgba(var(--color-dark), 0.03),
    0px 7px 15px rgba(var(--color-dark), 0.04),
    0px 0px 0px rgba(var(--color-dark), 0.04);

  /* Popup */
  --popup-bg: var(--ql-color-white);
  --popup-border-radius: var(--ql-border-radius-20);
  --popup-box-shadow: var(--box-shadow-default);

  /* Avatar */
  --avatar-color: var(--ql-typography-text-color-secondary);

  /* Facebook button */
  --facebook-button-default-color: var(
    --ql-typography-text-color-primary-reverse
  );
  --facebook-button-default-bg: rgba(59, 89, 152, 1);
  --facebook-button-hover-color: var(
    --ql-typography-text-color-primary-reverse
  );
  --facebook-button-hover-bg: rgba(
    59,
    89,
    152,
    var(--ql-color-blending-lighten1)
  );

  /* Apple button */
  --apple-button-default-color: var(--ql-typography-text-color-primary-reverse);
  --apple-button-default-bg: var(--ql-color-black);
  --apple-button-hover-color: var(--ql-typography-text-color-primary-reverse);
  --apple-button-hover-bg: var(--ql-color-black-t-lighten1);
}

/* Grid (breakpoints from @qonsoll/react-design) */
@media (min-width: 576px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-sm);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);
  }
}
@media (min-width: 768px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-md);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-md) * -1);
  }
}
@media (min-width: 992px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-lg);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-lg) * -1);
  }
}
@media (min-width: 1200px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xl) * -1);
  }
}
@media (min-width: 1600px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xxl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xxl) * -1);
  }
}
